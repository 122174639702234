import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";
import { useCallback, useState } from "react";
import { MdOutlineSave } from "react-icons/md";
import { useQueryClient } from "react-query";

import {
  CustomerAddress as CustomerAddressType,
  CustomerDetails as Customer,
  CustomerStatus,
} from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Spinner } from "@megaron/dash-spinner";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Email } from "@megaron/serializers";

import { CustomerAddress } from "./CustomerAddress";
import { CustomerPersonalDetails } from "./CustomerPersonalDetails";
import { RegionField } from "./RegionField";
import { StatusField } from "./StatusField";

const googleMapsLoader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

type Props = {
  customer: Customer;
  queryKey: string | string[];
  onCustomerDataEdit: (isEdited: boolean) => void;
};

export const CustomerDetails: React.FC<Props> = ({ customer, queryKey, onCustomerDataEdit }) => {
  const [isEdited, setIsEdited] = useState(false);

  const handleEdit = useCallback(
    (state: boolean) => {
      setIsEdited(state);
      onCustomerDataEdit(state);
    },
    [onCustomerDataEdit],
  );

  const isPhoneNumberEditDisabled = !!customer.loyaltyRegistrationStatus;
  const isCustomerDetailsEditDisabled =
    customer.loyaltyRegistrationStatus === "deleted" || customer.loyaltyRegistrationStatus === "registered";

  const { isMobile } = useDeviceType();

  const queryClient = useQueryClient();
  const updateCustomer = useClientManager("crm").saveCustomer().useMutation();

  const toast = useToast();

  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [phoneNumber, setPhoneNumber] = useState(customer.phoneNumber.toString());
  const [email, setEmail] = useState(customer.email?.toString());
  const [address, setAddress] = useState<CustomerAddressType>(customer.address);
  const [region, setRegion] = useState(customer.region);
  const [status, setStatus] = useState<CustomerStatus>(customer.status);

  const getLatLng = async (street: string, postalCode: string): Promise<[Decimal, Decimal] | [null, null]> => {
    const address = `${street}, ${postalCode}`;

    try {
      console.log("Loader options:", googleMapsLoader.options);

      await googleMapsLoader.load();

      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
            const location = results[0].geometry.location;
            resolve([new Decimal(location.lat()), new Decimal(location.lng())]);
          } else {
            resolve([null, null]);
          }
        });
      });
    } catch (error) {
      console.error("Error loading Google Maps API:", error);
      return [null, null];
    }
  };

  const updateCustomerMutation = useCallback(async () => {
    let updatedAddress = { ...address };

    if (address.street || address.postalCode) {
      const [latitude, longitude] = await getLatLng(address.street, address.postalCode);

      updatedAddress = { ...address, latitude, longitude };
    }

    updateCustomer.mutate(
      {
        uuid: customer.uuid,
        firstName: firstName,
        lastName: lastName,
        email: (email as Email) || null,
        phoneNumber: phoneNumber,
        address: updatedAddress,
        region: region,
        status: status,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          toast.info("Dane klienta zostały zaktualizowane");
          handleEdit(false);
        },
        onError: (error) => {
          toast.error("Nie udało się zaktualizować danych klienta");
        },
      },
    );
  }, [
    updateCustomer,
    customer.uuid,
    firstName,
    lastName,
    email,
    address,
    queryClient,
    queryKey,
    toast,
    phoneNumber,
    handleEdit,
    region,
    status,
  ]);

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div css={{ margin: "0 0 -1rem", display: "flex", gap: "0.625rem" }}>
        <RegionField
          customerUuid={customer.uuid}
          region={region}
          onRegionChange={(v) => {
            setRegion(v);
            handleEdit(true);
          }}
        />
        <StatusField
          currentStatus={status}
          onStatusChange={(status) => {
            setStatus(status);
            handleEdit(true);
          }}
        />
      </div>
      <CustomerPersonalDetails
        values={{ email, firstName, lastName, phoneNumber }}
        handlers={{
          onEmailChange: (v) => {
            setEmail(v);
            handleEdit(true);
          },
          onFirstNameChange: (v) => {
            setFirstName(v);
            handleEdit(true);
          },
          onLastNameChange: (v) => {
            setLastName(v);
            handleEdit(true);
          },
          onPhoneNumberChange: (v) => {
            setPhoneNumber(v);
            handleEdit(true);
          },
        }}
        isPhoneNumberEditDisabled={isPhoneNumberEditDisabled}
        isCustomerDetailsEditDisabled={isCustomerDetailsEditDisabled}
      />
      <CustomerAddress
        address={address}
        onAddressChange={(v) => {
          setAddress(v);
          handleEdit(true);
        }}
        isLoading={updateCustomer.isLoading}
      />
      {isEdited && (
        <Button
          css={{
            position: isMobile ? "fixed" : "unset",
            bottom: "3.5rem",
            right: "1rem",
            padding: "0.5rem 0.75rem",
            marginLeft: isMobile ? 0 : "auto",
          }}
          icon={updateCustomer.isLoading ? <Spinner size="18px" color="white" /> : <MdOutlineSave size={18} />}
          onClick={updateCustomerMutation}
        >
          Zapisz zmiany
        </Button>
      )}
    </div>
  );
};
