import { useTheme } from "@emotion/react";

import { Avatar } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { EditRegionDialog } from "./EditRegionDialog";

type Props = {
  customerUuid: Uuid;
  region: string | null;
  onRegionChange: (region: string | null) => void;
};

export const RegionField: React.FC<Props> = ({ customerUuid, region, onRegionChange }) => {
  const theme = useTheme();

  const regionQuery = useClientManager("crm")
    .regionsQuery()
    .useQuery({ regionName: region ?? undefined });

  const regionData = regionQuery.data?.items[0];

  const { profile } = useProfiles();

  const ownerProfile = regionData?.ownerEmail ? profile(regionData.ownerEmail) : null;

  const regionDialog = useDialogRoute(`/region`, ({ onClose }) => (
    <EditRegionDialog
      onClose={onClose}
      customerUuid={customerUuid}
      region={regionData?.name || null}
      onSuccess={(region) => {
        onRegionChange(region || null);
        onClose();
      }}
      skipRegionMutation
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        Region
      </label>
      <button
        onClick={regionDialog.open}
        css={{
          border: "none",
          width: "100%",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: "6px",
          backgroundColor: theme.colors.primaryLight,
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          minHeight: "38px",
        }}
      >
        {region && <Avatar src={ownerProfile?.profilePictureUrl ?? ""} size="20px" />}
        <span css={{ color: region ? theme.colors.primary : theme.colors.secondaryText }}>
          {region ? regionData?.name : "Wybierz..."}
        </span>
      </button>
      {regionDialog.element}
    </div>
  );
};
