import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useParams as useParamsCompat, useSearchParams } from "react-router-dom-v5-compat";

import { CustomerDetails } from "@megaron/crm-contracts";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { ManagerUseQueryReturn, useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { InteractionHistory } from "../interactions/InteractionHistory";
import { CustomerProfile } from "./CustomerProfile";
import { CustomerRelations } from "./CustomerRelations";
import { CustomerTabs, getActiveTab, historyTab, profileTab, relationsTab, saleTab } from "./CustomerTabs";
import { LoyaltyDetails } from "./LoyaltyDetails";

export type CustomerQuery = ManagerUseQueryReturn<CustomerDetails, "CustomerNotFound" | "UserNotAuthorized" | unknown>;

export const CustomerPage: React.FC = () => {
  const { customerId }: { customerId?: Uuid } = useParamsCompat();

  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <CustomerPageContent customerId={customerId} />;
};

const CustomerPageContent: React.FC<{ customerId: Uuid }> = ({ customerId }) => {
  const { isMobile, isDesktop } = useDeviceType();

  const [showAlertOnTabChange, setShowAlertOnTabChange] = useState(false);

  const [searchParams] = useSearchParams();

  const theme = useTheme();

  const customerQuery = useClientManager("crm").getCustomer().useQuery({ customerId });

  function getFullName(customer: { phoneNumber: string; firstName: string; lastName: string }) {
    const fullName =
      customer?.firstName && customer.lastName
        ? `${customer?.firstName} ${customer?.lastName}`
        : customer?.phoneNumber || "";

    return fullName.trim();
  }

  const { activeTab } = getActiveTab(searchParams);

  return (
    <>
      <QuerySkeleton query={customerQuery} height="58px" width="100%">
        {(customer) => (
          <>
            <HeaderBar
              header={getFullName(customer)}
              css={isMobile ? { boxShadow: "none", background: theme.colors.primary } : {}}
            />
            {isMobile && (
              <CustomerTabs
                customerId={customerId}
                css={{ position: "fixed", top: "53px", left: 0, right: 0, zIndex: 1000 }}
                showAlertOnTabChange={showAlertOnTabChange}
                onTabChange={() => setShowAlertOnTabChange(false)}
              />
            )}
          </>
        )}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          gap: isMobile ? 0 : "2rem",
          flexDirection: isMobile ? "column" : "row",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: isMobile ? "134px" : "83px",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
          {(!isMobile || (isMobile && activeTab === profileTab)) && (
            <CustomerProfile
              customerId={customerId}
              customerQuery={customerQuery}
              onCustomerDataEdit={(isEdited) => setShowAlertOnTabChange(isEdited)}
            />
          )}
          {(!isMobile || (isMobile && activeTab === historyTab)) && <InteractionHistory />}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: isDesktop ? "380px" : isMobile ? "100%" : "20rem",
            flexShrink: 0,
          }}
        >
          {(!isMobile || (isMobile && activeTab === saleTab)) && (
            <LoyaltyDetails
              loyaltyUuid={customerQuery.data?.uuid ?? null}
              isCustomerArchived={customerQuery.data?.isArchived ?? false}
            />
          )}
          {(!isMobile || (isMobile && activeTab === relationsTab)) && (
            <CustomerRelations customerQuery={customerQuery} />
          )}
        </div>
      </Page>
    </>
  );
};
