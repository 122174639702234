import { CustomerAddress as CustomerAddressType } from "@megaron/crm-contracts";
import { DescriptionField } from "@megaron/dash-description-list";
import { useDialogRoute } from "@megaron/dash-dialog";
import { GoogleMap } from "@megaron/dash-google-map";
import { SectionHeader } from "@megaron/dash-page";

import { EditCustomerAddressDialog } from "./EditCustomerAddressDialog";

type Props = {
  address: CustomerAddressType;
  onAddressChange: (address: CustomerAddressType) => void;
  isLoading: boolean;
};

export const CustomerAddress: React.FC<Props> = ({ address, isLoading, onAddressChange }) => {
  const editAddressDialog = useDialogRoute(`/edit-address`, ({ onClose }) => (
    <EditCustomerAddressDialog
      onClose={onClose}
      address={address}
      onAddressChange={onAddressChange}
      onSave={onClose}
      isLoading={isLoading}
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader isHr>Adres</SectionHeader>

      <button
        onClick={editAddressDialog.open}
        css={{ background: "none", border: "none", textAlign: "start", cursor: "pointer", padding: 0 }}
      >
        <DescriptionField label="Adres">
          {Object.values(address).some(Boolean)
            ? `${address.street} ${address.city} ${address.country} ${address.postalCode}`
            : "-"}
        </DescriptionField>
      </button>

      {address.latitude && address.longitude && (
        <GoogleMap latLng={{ latitude: address.latitude, longitude: address.longitude }} />
      )}

      {editAddressDialog.element}
    </div>
  );
};
