import { useTheme } from "@emotion/react";
import Papa from "papaparse";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { AvatarGroup } from "@megaron/dash-avatar";
import { Button, FileDrop, TextField } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Email, PhoneNumber } from "@megaron/serializers";
import { sleep } from "@megaron/utils";
import { newUuid } from "@megaron/uuid";

import { ImportedCustomersDialog } from "./importedCustomers/ImportedCustomersDialog";

type ImportedCustomerData = {
  phoneNumber: PhoneNumber;
  firstName: string | null;
  lastName: string | null;
  email: Email | null;
  region: string | null;
};

export type ImportedCustomer = ImportedCustomerData & { error?: string; uuid: string };

type Props = {
  onCustomerListSuccess: () => void;
  queryKey: string | string[];
};

export const AddCustomerBody: React.FC<Props> = (props) => {
  const theme = useTheme();
  const toast = useToast();
  const navigate = useNavigate();

  const preregisterMut = useClientManager("loyaltyAccounts").findAccount().useMutation();
  const addCustomerMut = useClientManager("crm").saveCustomer().useMutation();
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const queryClient = useQueryClient();

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>("+48" as PhoneNumber);
  const [region, setRegion] = useState<string | null>(null);
  const [origin, setOrigin] = useState<string | null>(null);

  const [importedCustomers, setImportedCustomers] = useState<ImportedCustomer[]>([]);
  const [isImportedCustomersDialogOpen, setIsImportedCustomersDialogOpen] = useState(false);

  const handleInputChange = (value: PhoneNumber) => {
    const filteredValue = value.replace(/[^0-9+]/g, "") as PhoneNumber;
    setPhoneNumber(filteredValue);
  };

  const addCustomerFromList = async (customer: ImportedCustomerData) => {
    const account = await preregisterMut.mutateAsync({
      phoneNumber: customer.phoneNumber,
      preregister: true,
    });

    // Wait for the preregistration to be handled by CRM
    if (account.isNew) await sleep(1500);

    try {
      await addCustomerMut.mutateAsync({
        uuid: account.uuid,
        region: customer.region,
        email: customer.email,
        firstName: customer.firstName ?? undefined,
        lastName: customer.lastName ?? undefined,
        phoneNumber: customer.phoneNumber,
      });

      setImportedCustomers((prev) => [...prev, { ...customer, error: undefined, uuid: account.uuid }]);
    } catch (e) {
      setImportedCustomers((prev) => [...prev, { ...customer, error: e as string, uuid: account.uuid }]);
    }
  };

  const handleFileChange = async (data: string | null, error: string | null) => {
    if (error) {
      console.error("Error occurred while handling file change:", error);
      toast.error("Error", "Failed to handle file change");
      return;
    }

    if (!data) {
      console.error("No data received while handling file change");
      return;
    }

    const file = new Blob([data], { type: "text/csv" });
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result as string;
      Papa.parse(text, {
        header: true,
        complete: async (results) => {
          const parsedData: ImportedCustomerData[] = results.data.map((item: any) => ({
            phoneNumber:
              getPropertyCaseInsensitive(item, "telefon") ?? getPropertyCaseInsensitive(item, "numer telefonu"),
            firstName: getPropertyCaseInsensitive(item, "imie"),
            lastName: getPropertyCaseInsensitive(item, "nazwisko"),
            email:
              getPropertyCaseInsensitive(item, "email") ??
              getPropertyCaseInsensitive(item, "mail") ??
              getPropertyCaseInsensitive(item, "adres email"),
            region: getPropertyCaseInsensitive(item, "region"),
          }));

          await Promise.all(parsedData.map((customer) => addCustomerFromList(customer)));

          queryClient.invalidateQueries(props.queryKey);

          setIsImportedCustomersDialogOpen(true);
        },
      });
    };

    reader.readAsText(file);
  };

  function getPropertyCaseInsensitive(obj: any, key: string): any {
    const variations = [key, key.toLowerCase(), key.toUpperCase(), key.replace(/^\w/, (c) => c.toUpperCase())];
    for (const variant of variations) {
      if (obj.hasOwnProperty(variant)) {
        return obj[variant];
      }
    }
    return null;
  }

  const addCustomer = async () => {
    const account = await preregisterMut.mutateAsync({
      phoneNumber,
      preregister: true,
    });

    // Wait for the preregistration to be handled by CRM
    if (account.isNew) await sleep(1500);

    addCustomerMut.mutate(
      { region, uuid: account.uuid },
      {
        onSuccess: (r) => {
          navigate(`/crm/customers/id/${account.uuid}`);
        },
        onError: (e) => {
          toast.error(e);
        },
      },
    );
  };

  const options = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  const customerOrigin = [
    "szkolenie",
    "social_media",
    "hurtownia",
    "polecenia",
    "baza_specjalista",
    "testy",
    "sucha_zabudowa",
  ].map((o) => ({
    label: o,
    value: o,
  }));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
        label={"Nr telefonu"}
        value={phoneNumber}
        onChange={(value: string) => handleInputChange(value as PhoneNumber)}
        autoFocus
      />

      {!region ? (
        <div css={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <SelectDialog
            label="Region"
            options={options}
            onSelect={(option) => setRegion(option?.value || null)}
            initiallySelectedValues={region ? [region] : []}
            variant="single-select"
          />
        </div>
      ) : (
        <div>
          <span
            css={{
              fontFamily: theme.displayFontFamily,
              fontSize: "14px",
              color: theme.colors.primary,
            }}
          >
            Region
          </span>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: theme.colors.primaryLight,
              padding: "8px 12px",
              borderRadius: theme.smallBorderRadius,
            }}
          >
            <div css={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <AvatarGroup profiles={[]} />
              {region}
            </div>

            <MdClose cursor="pointer" color={theme.colors.primary} onClick={() => setRegion(null)} />
          </div>
        </div>
      )}
      {/* 
      <SelectDialog
        options={customerOrigin}
        onSelect={(option) => setOrigin(option?.value || null)}
        initiallySelectedValues={origin ? [origin] : []}
        label="Pochodzenie"
        variant="single-select"
      /> */}
      {/* <FileDrop
        label="Lista klientów"
        onFileChange={handleFileChange}
        allowedExtensions={["csv"]}
        error={null}
        isLoading={addCustomerMut.isLoading || preregisterMut.isLoading}
      /> */}
      <Button
        css={{ alignSelf: "flex-end", margin: "0.25rem 0" }}
        onClick={addCustomer}
        isDisabled={addCustomerMut.isLoading || preregisterMut.isLoading}
      >
        Dalej
      </Button>
      {isImportedCustomersDialogOpen && (
        <ImportedCustomersDialog
          onClose={() => {
            setImportedCustomers([]);
            props.onCustomerListSuccess();
            setIsImportedCustomersDialogOpen(false);
          }}
          importedCustomers={importedCustomers}
        />
      )}
    </div>
  );
};
