import { useQueryClient } from "react-query";

import { Avatar } from "@megaron/dash-avatar";
import { useDialogRoute } from "@megaron/dash-dialog";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useProfiles } from "@megaron/megarax-webapps";

import { EditRegionDialog } from "./EditRegionDialog";

type Props = {
  customer: CustomerDoc;
  queryKey: string | string[];
};

export const OwnerProfile: React.FC<Props> = ({ customer, queryKey }) => {
  const { profile } = useProfiles();
  const ownerProfile = profile(customer.regionOwnerEmail);

  const queryClient = useQueryClient();

  const dialog = useDialogRoute(`/region/${customer.uuid}`, ({ onClose }) => (
    <EditRegionDialog
      onClose={onClose}
      customerUuid={customer.uuid}
      region={customer.region}
      onSuccess={(region, ownerEmail) => {
        const data = queryClient.getQueryData<{ items: CustomerDoc[]; count: number }>(queryKey);
        if (!data) return;

        const successData = data?.items.map((item) => {
          if (item.uuid === customer.uuid) {
            return { ...item, region, regionOwnerEmail: ownerEmail };
          }

          return item;
        });

        queryClient.setQueryData(queryKey, { items: successData, count: data.count });

        onClose();
      }}
    />
  ));

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    dialog.open();
  };

  return (
    <>
      <div
        onClick={handleAvatarClick}
        css={{
          height: "27px",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            transformOrigin: "center",
            transition: "transform 0.15s",
          },
        }}
      >
        <Avatar src={ownerProfile ? ownerProfile?.profilePictureUrl : null} size="27px" />
      </div>
      {dialog.element}
    </>
  );
};
