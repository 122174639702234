import { useTheme } from "@emotion/react";
import React, { useState } from "react";

import { Spinner } from "@megaron/dash-spinner";

interface FileDropProps {
  onFileChange: (data: string | null, error: string | null) => void;
  allowedExtensions: string[];
  instruction?: string;
  error: string | null;
  label?: string;
  mainText?: string;
  isLoading?: boolean;
}

export const FileDrop: React.FC<FileDropProps> = ({
  onFileChange,
  allowedExtensions,
  error,
  instruction,
  label,
  mainText,
  isLoading,
}) => {
  const theme = useTheme();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = e.dataTransfer.files;

    if (files && files.length === 1) {
      const inputFile = files[0];
      const fileExtension = inputFile.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        onFileChange(null, "Unsupported file type");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        onFileChange(event.target?.result?.toString() || null, null);
      };
      reader.onerror = () => {
        onFileChange(null, "Error reading file");
      };
      reader.readAsText(inputFile, "UTF-8");
    } else {
      onFileChange(null, "Please drop only one file");
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileExtension = file.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        onFileChange(null, "Unsupported file type");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        onFileChange(event.target?.result?.toString() || null, null);
      };
      reader.onerror = () => {
        onFileChange(null, "Error reading file");
      };
      reader.readAsText(file, "UTF-8");
    } else {
      onFileChange(null, "No file selected");
    }
  };

  return (
    <div>
      {label && (
        <span
          css={{
            fontFamily: theme.displayFontFamily,
            fontSize: "14px",
            color: theme.colors.primary,
            marginBottom: "4px",
          }}
        >
          {label}
        </span>
      )}
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          position: "relative",
          border: isDragOver ? `2px dashed ${theme.colors.primary}` : `2px dashed ${theme.colors.border}`,
          background: theme.colors.primaryLight,
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        {instruction && <p style={{ marginBottom: "10px" }}>{instruction}</p>}
        {isLoading ? (
          <Spinner size="22px" css={{ margin: "1rem auto" }} />
        ) : (
          <p css={{ color: isDragOver ? theme.colors.border : theme.colors.primary }}>
            {mainText ?? "Wybierz Plik..."}
          </p>
        )}
        <input
          type="file"
          onChange={handleFileInputChange}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
};
