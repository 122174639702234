import { CustomerAddress } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { LocationPicker } from "@megaron/dash-google-map";
import { useDeviceType } from "@megaron/dash-mq";

type Props = {
  onSave: () => void;
  onClose: () => void;
  address: CustomerAddress;
  onAddressChange: (newAddress: CustomerAddress) => void;
  isLoading?: boolean;
};

export const EditCustomerAddressDialog: React.FC<Props> = ({
  onSave,
  onClose,
  address,
  onAddressChange,
  isLoading,
}) => {
  const { isMobile } = useDeviceType();

  return (
    <Dialog onClose={() => onClose()} placement={isMobile ? "top" : "center"} header={"Dane klienta"}>
      <div css={{ display: "flex", flexDirection: "column", maxWidth: "600px", margin: "auto", gap: "20px" }}>
        <LocationPicker
          address={address}
          onChange={(newAddress: CustomerAddress) => onAddressChange({ ...address, ...newAddress })}
        />
        <Button
          onClick={() => {
            onSave();
            onClose();
          }}
          isLoading={isLoading}
          css={{ alignSelf: "flex-end" }}
        >
          Zatwierdź
        </Button>
      </div>
    </Dialog>
  );
};
